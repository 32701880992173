import { PedidoModel, Produto } from "@/models/SugestaoPedidoModel";

import ApiService from "./ApiService";

type ListaPedidos = PedidoModel [];

export const getListaPedidos = async (codConcessionaria: any): Promise<ListaPedidos> => {
    const { data } = await ApiService.get(`/getSugestaoPedidos/${codConcessionaria}`);
    return data;
};

export const salvarSugestaoPedido = async (informacoesItensTodosProdutos) => {
    const { data } = await ApiService.post(`/salvarSugestaoPedidos`, informacoesItensTodosProdutos);
    return data
};

export const getSugestaoPedidosEstoqueItens = async (codConcessionaria: any, codSugestaoPedido: number): Promise<Produto[]> => {
    const { data } =  await ApiService.get(`/getSugestaoPedidosEstoqueItens/${codConcessionaria}/${codSugestaoPedido}`);
    return data
};

export const getlogSugestaoPedidos = async (codSugestaoPedido) => {
    const { data } =  await ApiService.get(`/getlogSugestaoPedidos/${codSugestaoPedido}`);
    return data
};


export default { getListaPedidos, salvarSugestaoPedido, getSugestaoPedidosEstoqueItens };
